* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
  font-family: inter;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvas {
  pointer-events: none;
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.view1 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.view2 {
  position: absolute;
  top: 2em;
  right: 2em;
  width: calc(250px - 2em);
  height: calc(250px - 2em);
}
